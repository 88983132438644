import { IconDefinition, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState, useEffect } from 'react';

export interface ButtonProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    title: string,
    action?: (arg?: any) => void;
    nosubmit?: boolean;
    icon?: IconDefinition;
    iconright?: boolean;
    secondary?: boolean;
    disabled?: boolean;
    notRelative?: boolean;
    active?: boolean;
    tabIndex?: number;
}

const Button = (props: ButtonProps) => {
    const { title, action, className, secondary, disabled, nosubmit, iconright, icon, notRelative, active, tabIndex, ...restProps } = props;

    const spinning = icon === faSpinner;
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [sizeClass, setSizeClass] = useState('snow-button');

    const today = new Date();
    const isWinter = (today.getMonth() === 11) || (today.getMonth() === 0 && today.getDate() <= 5);

    useEffect(() => {
        if (buttonRef.current && isWinter) {
            const { offsetWidth } = buttonRef.current;
            const random = Math.random() < 0.5;

            if (offsetWidth < 130) {
                setSizeClass(random ? 'snow-button-1' : 'snow-button-3');
            } else {
                setSizeClass(offsetWidth < 150 ? random ? 'snow-button-2' : 'snow-button-5' : offsetWidth < 250 ? 'snow-button' : 'snow-button-4');
            }
        }
    }, []);

    return (
        <button
            tabIndex={disabled ? -1 : tabIndex || 0}
            ref={buttonRef}
            type={nosubmit ? 'button' : 'submit'}
            {...restProps}
            className={`
                rounded-[0.4rem] flex cursor-pointer transition-all duration-300 ease-in-out px-6 py-2 justify-center gap-4 items-center
                ${(!notRelative || active) && 'relative'} 
                ${disabled && 'pointer-events-none opacity-40'} 
                ${sizeClass}
                ${active ? 
                    (secondary ? 
                        'bg-light-100 border-2 text-gray-700 border-accent-light' : 
                        'text-white bg-accent-light') : 
                    (secondary ? 
                        'hover:bg-light-100 focus:bg-light-100 border-2 text-gray-700 border-accent-light' : 
                        'text-white bg-accent hover:bg-accent-light focus:bg-accent-light')} 
                ${className}
            `}
            onClick={(e) => {
                if (props.action) props.action(e);
                if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur();
                }
            }}
        >
            {active && <div className='absolute -left-2 -top-2 -right-2 -bottom-2 border-4 border-blue-200 rounded-[0.4rem]' />}
            {icon && !iconright && <FontAwesomeIcon className={`${spinning ? 'animate-spin' : ''}`} icon={icon} />}
            {title}
            {icon && iconright && <FontAwesomeIcon className={`${spinning ? 'animate-spin' : ''}`} icon={icon} />}
        </button>
    );
};

export default Button;
