export default {
    API_URL: 'https://api.digipas.dev',
    DOWNLOAD_URL_IOS: 'https://apps.apple.com/nl/app/digipas.app/id1633608697',
    DOWNLOAD_URL_ANDROID: 'https://play.google.com/store/apps/details?id=com.xinteractive.android.digipas',
    DOWNLOAD_URL_CARDS_TEMPLATE: 'https://api.digipas.dev/assets/import-template.csv',
    INVITE_LINK_PREFIX: 'https://api.digipas.dev/activate/',
    INVITE_CODE_LINK: 'https://api.digipas.dev/code',
    PATRICKAI_PORTAL_URL: 'https://portal-patrick-ai.xint.dev/',
    PATRICKAI_API_URL: 'https://api-patrick-ai.xint.dev',
    PATRICKAI_API_KEY: 'a8f9e5c2-d32b-41e6-9b7c-4cfa627b7d2e'
}