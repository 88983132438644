import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Main from './Main';
import { useAppDispatch, useAppSelector } from '../Redux/store';
import Sidebar from './Shared/Sidebar/Sidebar';
import SidebarItem from './Shared/Sidebar/SidebarItem';
import SidebarUser from './Shared/Sidebar/SidebarUser';
import { JWT } from '../Utils/JWT';
import WizardPage from './Pages/Dashboard/Wizard/WizardPage';
import LoadingPage from './Pages/LoadingPage';
import { useLazyGetOrganisationSettingsQuery } from '../Redux/Api/Organisations';
import { useTranslation } from 'react-i18next';
import { Snackbar } from './Layout/Snackbar';
import WinterStyle from './Shared/WinterStyle';
import { useGetUserQuery } from '../Redux/Api/Users';
import { logout } from '../Redux/State/Auth';
import Constants from '../Constants';

interface DashboardWrapperProps {
    onSettingsUpdate: (notification: boolean, sponsors: boolean, patrickAssistant: boolean) => void;
}

const DashboardWrapper: React.FC<DashboardWrapperProps> = ({ onSettingsUpdate }) => {
    const org_id = useAppSelector((s) => s.organisation.id)!;
    const theme = useAppSelector((s) => s.organisation.settings?.theme);
    const [hasLoaded, setHasLoaded] = useState(false);
    const is_new = useAppSelector((s) => s.organisation.settings?.new) || false;

    const roles = JWT.read<Array<string>>('roles') || [];
    const first_name = JWT.read('given_name');
    const last_name = JWT.read('family_name');
    const userId = JWT.read('sub');

    const [getOrganisationsSettings, { data: settings }] = useLazyGetOrganisationSettingsQuery();
    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    const dispatch = useAppDispatch();

    // check onload or reload for the active users data
    const { data, refetch, isError } = useGetUserQuery(userId as string, {
        skip: !userId,
    });

    useEffect(() => {
        // handle users that don't have access to the api call
        if (isError && roles[0] !== 'ROLE_SOCIAL') {
            runLogout();
        }
    }, [isError]);

    useEffect(() => {
        if (!data) return

        // if data differce from the jwt force the user to relogin
        if (data.firstname !== first_name || data.lastname !== last_name || data.role !== roles[0]) {
            runLogout();
        }

    }, [data])

    const [adminFeaturesDisabled, setAdminFeaturesDisabled] = useState(false);

    const today = new Date();
    const isWinter = today.getMonth() === 11 || (today.getMonth() === 0 && today.getDate() <= 5);

    const super_admin = roles.includes('ROLE_SUPER_ADMIN');
    const isSocialUser = roles.includes('ROLE_SOCIAL');

    useEffect(() => {
        if (org_id) getOrganisationsSettings(org_id);
    }, [org_id, getOrganisationsSettings]);

    useEffect(() => {
        if (theme) setHasLoaded(true);
    }, [theme]);

    useEffect(() => {
        if (settings) {
            onSettingsUpdate(settings.notification, settings.sponsors, settings.patrickAI);
        }
    }, [settings, onSettingsUpdate]);

    const runLogout = () => {
        dispatch(logout());
        window.location.hash = '#re-login';
    };

    const handleVisibilityChange = (visibilityMode: boolean) => {
        setAdminFeaturesDisabled(visibilityMode);
    };

    return (
        <>
            <style>
                {`
                    :root {
                        --color-accent: ${theme?.colors.tint || 'rgb(234, 45, 55)'};
                        --color-accent-light: ${theme?.colors.lighterTint || 'rgb(238, 88, 96)'};
                    }
                `}
            </style>

            {isWinter && (<WinterStyle />)}

            <Sidebar style={{ boxShadow: '0px 3px 40px #294D910F' }}>
                {(!is_new || super_admin) && (
                    <div className={`flex flex-col flex-grow overflow-y-auto overflow-x-hidden no-scrollbar mt-5 ${is_new && 'opacity-75'}`}>
                        <SidebarItem exact link={`/${lang}/dashboard`} title={t('sidebar:dashboard')} />

                        {!isSocialUser && (
                            <SidebarItem link={`/${lang}/dashboard/cards`} title={t('sidebar:cards')}>
                                <SidebarItem sub link={`/${lang}/dashboard/cards/list`} title={t('sidebar:list')} />
                                <SidebarItem sub link={`/${lang}/dashboard/cards/create`} title={t('sidebar:add')} />
                                <SidebarItem sub link={`/${lang}/dashboard/cards/import`} title={t('sidebar:import')} />
                            </SidebarItem>
                        )}

                        <SidebarItem link={`/${lang}/dashboard/organisation`} title={t('sidebar:organisation')}>
                            <SidebarItem sub link={`/${lang}/dashboard/organisation/appbuilder`} title={t('sidebar:app')} />
                            <SidebarItem sub link={`/${lang}/dashboard/organisation/theme`} title={t('sidebar:theme')} />
                            <SidebarItem sub link={`/${lang}/dashboard/organisation/socials`} title={t('sidebar:social')} />
                        </SidebarItem>

                        <SidebarItem link={`/${lang}/dashboard/news`} title={t('sidebar:news')} >
                            <SidebarItem sub link={`/${lang}/dashboard/news/list`} title={t('sidebar:list')} />
                            <SidebarItem sub link={`/${lang}/dashboard/news/create`} title={t('sidebar:add')} />
                        </SidebarItem>

                        {settings?.notification ? (
                            <SidebarItem link={`/${lang}/dashboard/notify`} title={t('sidebar:notification')} />
                        ) : null}

                        {!isSocialUser && (
                            <SidebarItem link={`/${lang}/dashboard/users`} title={t('sidebar:user')}>
                                <SidebarItem sub link={`/${lang}/dashboard/users/list`} title={t('sidebar:list')} />
                                <SidebarItem sub link={`/${lang}/dashboard/users/create`} title={t('sidebar:add')} />
                            </SidebarItem>
                        )}

                        {settings?.sponsors && (
                            <SidebarItem link={`/${lang}/dashboard/sponsor`} title={t('sidebar:sponsors')}>
                                <SidebarItem sub link={`/${lang}/dashboard/sponsor/list`} title={t('sidebar:list')} />
                                <SidebarItem sub link={`/${lang}/dashboard/sponsor/create`} title={t('sidebar:add')} />
                            </SidebarItem>
                        )}

                        {super_admin && !adminFeaturesDisabled && (
                            <SidebarItem bottom link={`/${lang}/dashboard/admin`} title="Admin">
                                <SidebarItem sub link={`/${lang}/dashboard/admin/organisation/list`} title={t('sidebar:organisations')} />
                                <SidebarItem sub link={`/${lang}/dashboard/admin/notes/list`} title={t('sidebar:note')} />
                                <SidebarItem sub link={`/${lang}/dashboard/admin/suggestion/list`} title={t('patrickai:suggestion')} />
                                <SidebarItem sub targetBlank link={Constants.PATRICKAI_PORTAL_URL} title={t('sidebar:patrickai')} />
                            </SidebarItem>
                        )}

                    </div>
                )}
                <SidebarUser onVisibilityChange={handleVisibilityChange} />
            </Sidebar>
            <Main>
                {is_new && !super_admin ? (
                    hasLoaded ? <WizardPage /> : <LoadingPage />
                ) : (
                    <>
                        <Outlet />
                        <Snackbar />
                    </>
                )}
            </Main>
        </>
    );
};

export default DashboardWrapper;
