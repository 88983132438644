import { AnimatePresence, motion } from 'framer-motion';
import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSearch } from '@fortawesome/pro-solid-svg-icons';

export interface FilePickerProps extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'onChange'> {
    label: string;
    sublabel?: string;
    initial?: string;
    id?: string;
    required?: boolean;
    noBottomMargin?: boolean;
    accept?: Array<string>;
    onChange?: (file: File) => void;
    onChangeBlob?: (blob: string) => void;
    submitted?: boolean;
}

export type FilePickerHandle = {
    clearInput: () => void;
};

const FilePicker: ForwardRefRenderFunction<FilePickerHandle, FilePickerProps> = (props: FilePickerProps, ref?) => {
    const { label, sublabel, onChange, onChangeBlob, required, accept, noBottomMargin, initial, id, submitted, ...restProps } = props;

    const labelOrId = id || label;
    const { t } = useTranslation();
    const [value, setValue] = useState(initial || '');
    const [error, setError] = useState('');
    const [isDragging, setIsDragging] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
        clearInput() {
            setValue('');
            setError('');
            if (inputRef.current) {
                inputRef.current.value = '';
            }
        },
    }));

    const validateFile = (file: File) => {
        const acceptPatterns = accept || [];
        const mimeType = file.type;

        // Check if the file is a webp image
        if (mimeType === 'image/webp') {
            setError(t('invalidFileType') as string);
            return false;
        }

        let isValid = false;

        for (const pattern of acceptPatterns) {
            if (pattern === '*/*') {
                isValid = true;
                break;
            }
            if (pattern.endsWith('/*')) {
                const basePattern = pattern.split('/')[0];
                const baseMimeType = mimeType.split('/')[0];
                if (basePattern === baseMimeType) {
                    isValid = true;
                    break;
                }
            } else {
                if (mimeType === pattern) {
                    isValid = true;
                    break;
                }
            }
        }

        return isValid;
    };

    const handleFile = (file: File) => {
        if (!validateFile(file)) {
            setError(t('invalidFileType') || 'Invalid file type');
            return;
        } else {
            setError('');
            setValue(file.name);
        }

        if (onChange) onChange(file);

        const blob = URL.createObjectURL(file);

        if (onChangeBlob) onChangeBlob(blob);
    };

    const onFileSelect = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const file = evt.currentTarget.files?.item(0);
        if (file) handleFile(file);
    };

    const onDragOver = (evt: React.DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
        setIsDragging(true);
    };

    const onDragLeave = () => {
        setIsDragging(false);
    };

    const onDrop = (evt: React.DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
        setIsDragging(false);

        const file = evt.dataTransfer.files[0];
        if (file) {
            handleFile(file);

            if (inputRef.current) {
                const dataTransfer = new DataTransfer();
                dataTransfer.items.add(file);
                inputRef.current.files = dataTransfer.files;
            }
        }
    };

    const onClickFilePicker = () => {
        if (inputRef.current) {
            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
            }
            inputRef.current.click();
        }
    };

    const onKeyFilePicker = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    return (
        <div {...restProps} className={`flex flex-col ${noBottomMargin ? 'mb-0' : 'mb-6'} w-full`}>
            <label htmlFor={labelOrId}>
                {label}
                {required && <span className="text-red-400">*</span>}
                {sublabel && <span className="opacity-70 text-sm">({sublabel})</span>}
            </label>
            <div
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
                onClick={onClickFilePicker}
                onKeyDown={(e) => e.key === 'Enter' && onKeyFilePicker()}
                tabIndex={0}
                className={`flex flex-col sm:flex-row mt-2 rounded-[0.4rem] group
                ${submitted && !value ? 'border-red-500' : 'border-black border-opacity-10'}
                border-[1px] p-1 cursor-pointer relative ${isDragging ? 'bg-gray-200' : ''}`}
            >
                <div className="flex rounded-[0.4rem] cursor-pointer bg-accent transition-colors group-hover:bg-accent-light group-focus:bg-accent-light px-6 py-2 text-white justify-center gap-2 items-center whitespace-nowrap relative snow-button">
                    <FontAwesomeIcon icon={faFileSearch} />{t('organisation:theme:file')}
                </div>
                <div className="flex items-center ml-0 sm:ml-4 break-all text-[16px]">{value || t('noFile')}</div>

                <AnimatePresence>
                    {isDragging && (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="absolute -top-2 -left-2 -bottom-2 -right-2 border-2 border-dashed border-gray-500 rounded-lg flex items-center justify-center backdrop-blur-md bg-gray-50/70"
                        >
                            {t('mapping:dropPrompt')}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
            <input
                ref={inputRef}
                hidden
                onChange={onFileSelect}
                type="file"
                accept={(accept || []).join(',')}
                name={labelOrId}
                id={labelOrId}
            />
            <input name={`@VALID@${labelOrId}`} type="hidden" value={(value.length > 0).toString()} />

            <motion.div
                animate={!error ? { height: '0rem' } : { height: '1.2rem' }}
                className="text-sm overflow-hidden text-red-400 mb-2"
            >
                {error}
            </motion.div>
            <motion.div
                animate={(value.length > 0 || !required) ? { height: '0rem' } : { height: '1.2rem' }}
                className="text-sm overflow-hidden text-red-400 mb-2"
            >
                {(value.length <= 0 && required && t('required')) || '\u00a0'}
            </motion.div>
        </div>
    );
};

export default forwardRef(FilePicker);
